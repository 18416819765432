import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Swiper, SwiperSlide } from "swiper/react"
import { EffectFade, Pagination, Autoplay, Navigation } from "swiper"
import Container from "../components/container"
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"
import "swiper/css/effect-fade"

const Slider = ({ items }) => {

  return (
      <Swiper
        grabCursor={true}
        loop={true}
        autoplay={{
          delay: 4500,
          disableOnInteraction: false,
        }}
        pagination={{
          dynamicBullets: true,
          clickable: true,
        }}
        navigation={true}
        className="h-full w-full relative"
        modules={[EffectFade, Autoplay, Pagination, Navigation]}
        effect="fade"
      >
        {items.map((item, index) => {
          return (
            <SwiperSlide key={`slide-${index}`}>
              {item && (
                <>
                  <GatsbyImage
                    className="h-full w-full"
                    image={item?.image?.gatsbyImageData}
                    alt={item?.title ? item?.title : ""}
                  />
                  <div className={"w-full bg-white absolute bottom-0 z-30"}>
                    <Container>
                      <div className="flex flex-row justify-between items-end py-4">
                        <div className="text-xs tracking-widest text-gray-600">
                          <div className="mb-1">
                            {item?.titleLeft && (
                              <>Bild Rechte: {item?.titleLeft}</>
                            )}
                          </div>
                          © {new Date().getFullYear()} dichter
                          Architekturgesellschaft mbH
                        </div>
                        <div className="flex flex-col text-right">
                          <Link
                            to={"/impressum"}
                            activeClassName="!text-gold"
                            partiallyActive={true}
                            className="block hover:text-gold uppercase tracking-widest text-xs text-gray-600"
                          >
                            Impressum
                          </Link>
                          <Link
                            to={"/datenschutz"}
                            activeClassName="!text-gold"
                            partiallyActive={true}
                            className="block hover:text-gold uppercase tracking-widest text-xs text-gray-600"
                          >
                            Datenschutz
                          </Link>
                        </div>
                      </div>
                    </Container>
                  </div>
                </>
              )}
            </SwiperSlide>
          )
        })}
      </Swiper>
    
  )
}

Slider.propTypes = {
  items: PropTypes.array.isRequired,
}

export default Slider
